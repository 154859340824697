import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { Container } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import AnchorPrismic from "../components/elements/AnchorPrismic";

import { constructActiveDoc } from "../utilities";

const SimplePage = ({ data }) => {
  if (!data) return null;

  const { prismicSettings: settings, prismicSimplePage: page } = data;
  const { title, content } = page.data;

  return (
    <>
      <Seo page={page} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <Container>
          <h1 className="mb-5 py-5 text-center">{title}</h1>
          <RichText
            render={content.richText}
            serializeHyperlink={AnchorPrismic}
          />
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query simplePageQuery($lang: String, $id: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    prismicSimplePage(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicSimplePageFragment
    }
  }
`;

export default SimplePage;
